<template>
  <div>
    <v-container class="custom-container p-register">
      <v-card class="mx-auto">
        <v-card-text class="pa-10">
          <v-form autocomplete="off" @submit.prevent="handleRegistro">
            <v-row>
              <template>
                <v-col cols="12" class="pb-8">
                  <h1 class="font-weight-regular">Crear una cuenta</h1>
                </v-col>

                <v-col cols="12" md="4" class="pt-0">
                  <v-text-field
                      class="text-sm"
                      type="text"
                      v-model="rut"
                      label="Rut"
                      placeholder="Ej: 17065987-5"
                      :change="formatRut()"
                      :error-messages="errors.errorsRut($v.rut)"
                      @input="delayTouch($v.rut)"
                      @blur="$v.rut.$touch()"
                      :disabled="registrandoCliente"
                      :autofocus="!$vuetify.breakpoint.mobile"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="8" class="pt-0">
                  <v-text-field
                      clearable
                      clear-icon="mdi-delete"
                      class="text-sm"
                      label="Nombre completo"
                      v-model="nombreCompleto"
                      type="text"
                      :error-messages="errors.errorNombreCompleto($v.nombreCompleto)"
                      @input="delayTouch($v.nombreCompleto)"
                      @blur="$v.nombreCompleto.$touch()"
                      placeholder="Ej: James Saúl Morgan McGill"
                      :disabled="registrandoCliente"
                      v-disabled-icon-focus
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="pt-0">
                  <v-text-field
                      class="text-sm"
                      type="tel"
                      v-model="telefono"
                      label="Teléfono"
                      counter
                      placeholder="Ej: 912389076"
                      :error-messages="errorTelefono"
                      @blur="$v.telefono.$touch"
                      @input="delayTouch($v.telefono)"
                      clearable
                      clear-icon="mdi-delete"
                      :disabled="registrandoCliente"
                      v-disabled-icon-focus
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="pt-0">
                  <v-autocomplete class="text-sm" style=""
                                  v-model="comuna"
                                  :items="items"
                                  :loading="isLoading"
                                  :search-input.sync="search"
                                  hide-no-data
                                  hide-selected
                                  item-text="Description"
                                  item-value="API"
                                  label="Comuna"
                                  placeholder="Ej: Coquimbo, Coquimbo"
                                  return-object
                                  :error-messages="errorComuna"
                                  @blur="$v.comuna.$touch()"
                                  :disabled="registrandoCliente"
                  ></v-autocomplete>

                </v-col>

                <v-col cols="12" class="pt-0">
                  <vuetify-google-autocomplete
                      label="Domicilio"
                      v-model="direccion"
                      :error-messages="errorDireccion"
                      @input="delayTouch($v.direccion)"
                      @blur="$v.direccion.$touch"
                      id="map"
                      placeholder="Escriba la dirección de su domicilio."
                      country="CL"
                      @placechanged="mostrarEnMapa"
                      :disabled="registrandoCliente"
                  >
                  </vuetify-google-autocomplete>
                </v-col>

                <v-col cols="12" class="pt-0">
                  <v-text-field
                      class="text-sm"
                      v-model="complementoDomicilio"
                      type="text"
                      clearable
                      clear-icon="mdi-delete"
                      label="Complementar domicilio"
                      placeholder="Departamento, referencia de calle, etc."
                      :disabled="registrandoCliente"
                      v-disabled-icon-focus
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <GmapMap
                      :center="centerMap"
                      :zoom="14"
                      :options="{
                      streetViewControl: false,
                    }"
                      map-type-id="terrain"
                      style="height: 300px"
                  >
                    <GmapMarker
                        v-if="markerMap.lat != null"
                        :position="markerMap"
                    />
                  </GmapMap>
                </v-col>


                <v-col cols="12" md="6" class="pt-0">
                  <v-text-field
                      class="text-sm"
                      type="email"
                      v-model="correo"
                      label="Correo electrónico"
                      placeholder="Ej: jimmy_mcgill@gmail.com"
                      :error-messages="errors.errorCorreo($v.correo)"
                      @blur="$v.correo.$touch"
                      @input="delayTouch($v.correo)"
                      clearable
                      clear-icon="mdi-delete"
                      :disabled="registrandoCliente"
                      v-disabled-icon-focus
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="pt-0">
                  <v-text-field
                      class="text-sm"
                      v-model="correoConfirmacion"
                      label="Confirmar correo electrónico"
                      placeholder="Vuelva a ingresar su correo electrónico"
                      :error-messages="errorCorreoRepeat"
                      @blur="$v.correoConfirmacion.$touch"
                      @input="delayTouch($v.correoConfirmacion)"
                      clearable
                      clear-icon="mdi-delete"
                      :disabled="registrandoCliente"
                      v-disabled-icon-focus
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="pt-0">
                  <v-text-field
                      class="text-sm"
                      v-model="contrasena"
                      label="Contraseña"
                      :type="verPass ? 'text' : 'password'"
                      placeholder="Cree una nueva contraseña"
                      :error-messages="errorPass"
                      @blur="$v.contrasena.$touch"
                      @input="delayTouch($v.contrasena)"
                      :disabled="registrandoCliente"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="pt-0">
                  <v-text-field
                      class="text-sm"
                      name="input-10-1"
                      v-model="contrasenaConfirmacion"
                      label="Confirmar contraseña"
                      :append-icon="verPass ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="verPass ? 'text' : 'password'"
                      @click:append="verPass = !verPass"
                      :error-messages="errorPassRepeat"
                      @blur="$v.contrasenaConfirmacion.$touch"
                      @input="delayTouch($v.contrasenaConfirmacion)"
                      placeholder="Confirme su contraseña"
                      :disabled="registrandoCliente"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="pt-0">
                  <v-checkbox
                      class="mt-0"
                      v-model="terminos_y_condiciones"
                      :error-messages="errorsTerminosYCondiciones"
                      :disabled="registrandoCliente"
                  >
                    <template v-slot:label>
                      <div class="text-sm">
                        Acepto los
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a
                                target="_blank"
                                href="/informacion/tyc"
                                @click.stop
                                v-on="on"
                            >
                              términos y condiciones
                            </a>
                          </template>
                          Ver términos y condiciones
                        </v-tooltip>
                        .
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>

                <v-fade-transition>
                  <v-col cols="12" v-if="showError.show">
                    <v-alert
                        outlined
                        type="warning"
                        border="left"
                    >{{ showError.error }}
                    </v-alert>
                  </v-col>
                </v-fade-transition>

                <v-col cols="12" class="d-flex justify-end">
                  <v-btn type="submit" color="primary" :loading="registrandoCliente" small>
                    <v-icon left small>mdi-account-plus</v-icon>
                    Crear cuenta
                  </v-btn>
                </v-col>

              </template>
            </v-row>
          </v-form>

        </v-card-text>
      </v-card>
      <simple-modal-static :ver-dialog="modalRegistroUsuario">
        <div class="text-center">
          <p class="mb-0  ">
            Estimado cliente, su cuenta ha sido registrada con éxito. <br>Le hemos enviado un correo electrónico a <b>{{ correo }}</b> con instrucciones para confirmar su cuenta.
          </p>
          <v-btn color="info" outlined class="mt-4 px-7" to="/">
            <span class="">Ir al inicio</span>
          </v-btn>
        </div>
      </simple-modal-static>
    </v-container>

  </div>
</template>

<script>
import {rutClean, rutFormat, rutValidate} from "rut-helpers";
import usuarioEntity from "@/model/usuario/usuario.entity";

// eslint-disable-next-line no-unused-vars
import {required, minLength, maxLength, sameAs, helpers} from 'vuelidate/lib/validators'
import DisabledIconFocusDirective from "@/directives/DisabledIconFocusDirective";
import validEntity from "@/model/validaciones/valid.entity";
import SimpleModalStatic from "@/components/SimpleModalStatic";
import {nombreApellidoValidate} from "@/model/validaciones/valid.entity";


const touchMap = new WeakMap();

export default {
  components: {SimpleModalStatic},
  directives: {DisabledIconFocusDirective},
  data() {
    return {
      //from import:
      rutValidate: rutValidate,
      buscarComuna: usuarioEntity.getAllComunasDeRegiones,
      //----
      rutClean,
      rut: "",
      nombreCompleto: "",
      region: null,
      ciudad: null,
      direccion: "",
      complementoDomicilio: "",
      telefono: "",
      correo: "",
      correoConfirmacion: "",
      contrasena: "",
      contrasenaConfirmacion: "",
      verPass: false,
      verPassConfirm: false,
      terminos_y_condiciones: false,
      error_terminos: false,
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      comuna: null,
      search: null,
      registrandoCliente: false,
      showError: {
        show: false,
        error: ""
      },
      markerMap: {
        lat: null,
        lng: null
      },
      centerMap: {
        lat: -29.95832,
        lng: -71.34047
      },
      modalRegistroUsuario: false,
      errors: validEntity
    };
  },
  validations: {
    rut: {required, rutValidate},
    nombreCompleto: {required, nombreApellidoValidate},
    direccion: {required},
    telefono: {required, minLength: minLength(9), maxLength: maxLength(9)},
    correo: {required},
    correoConfirmacion: {
      required,
      sameAsEmail: sameAs('correo')
    },
    contrasena: {
      required,
      minLength: minLength(6)
    },
    contrasenaConfirmacion: {
      required,
      sameAsPassword: sameAs('contrasena')
    },
    comuna: {required},
    terminos_y_condiciones: {
      accepted: val => val === true
    }
  },
  created() {

    this.isLoading = true
    this.buscarComuna().then(response => {
      this.entries = response;
    }).catch(error => {
      console.log("Error: ", error)
    }).finally(() => (this.isLoading = false))
  },
  computed: {
    errorComuna() {
      const errors = [];
      if (!this.$v.comuna.$dirty) return errors;
      !this.$v.comuna.required && errors.push("Ingrese la comuna en la cual reside");
      return errors;
    },
    errorPassRepeat() {
      const errors = [];
      if (!this.$v.contrasenaConfirmacion.$dirty) return errors;
      !this.$v.contrasenaConfirmacion.sameAsPassword && errors.push("Las contraseñas no coinciden");
      return errors;
    },
    errorPass() {
      const errors = [];
      if (!this.$v.contrasena.$dirty) return errors;
      !this.$v.contrasena.required && errors.push("Ingrese una contraseña");
      !this.$v.contrasena.minLength && errors.push("La contraseña debe contener al menos 6 caracteres");
      return errors;
    },

    errorCorreoRepeat() {
      const errors = [];
      if (!this.$v.correoConfirmacion.$dirty) return errors;
      !this.$v.correoConfirmacion.sameAsEmail && errors.push("Verifique que ambos correos electrónicos sean idénticos.");
      return errors;
    },


    errorTelefono() {
      const errors = [];
      if (!this.$v.telefono.$dirty) return errors;
      !this.$v.telefono.required && errors.push("Ingrese su número telefónico (9 dígitos)");
      !this.$v.telefono.minLength && errors.push("El número debe contener 9 dígitos");
      !this.$v.telefono.maxLength && errors.push("El número debe contener 9 dígitos");

      return errors;
    },

    errorDireccion() {
      const errors = [];
      if (!this.$v.direccion.$dirty) return errors;
      !this.$v.direccion.required && errors.push("Ingrese su dirección principal");
      return errors;
    },

    errorsTerminosYCondiciones() {
      const errors = [];
      if (!this.$v.terminos_y_condiciones.$dirty) return errors;
      this.terminos_y_condiciones === false && errors.push("Debe aceptar los términos y condiciones");
      return errors;
    },
    items() {
      return this.entries.map(entry => {
        const Description = entry.comuna + ", " + entry.region

        return Object.assign({}, entry, {Description})
      })
    },
  },

  methods: {
    formatRut() {
      this.rut = rutFormat(this.rut);
    },

    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },

    async handleRegistro() {


      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.showError.show = false;
      this.showError.error = "";

      this.registrandoCliente = true;

      let objRegistro = {
        rut_cliente: rutClean(this.rut).slice(0, -1),
        nombre_completo: this.nombreCompleto,

        id_comuna_FK: this.comuna.id_comuna,
        direccion: this.direccion,
        telefono: this.telefono,
        correo: this.correo,
        contrasena: this.contrasena,
      };

      await this.$recaptchaLoaded();
      // Execute reCAPTCHA with action "login".
      var t = await this.$recaptcha('login');


      usuarioEntity.registrarUsuario(objRegistro, t).then((response) => {


        if (response.rut_cliente != null) {
        this.modalRegistroUsuario = true;
        } else if (response.toLowerCase() === "cliente repetido") {
          this.showError.show = true;
          //TODO: crear link para recuperar contraseña.
          this.showError.error = "Ya existe un cliente registrado con el rut ingresado. Tal vez desee **recuperar su contraseña**."
        }


      }).catch(error => {
        this.showError.show = true;
        this.showError.error = error
      }).finally(() => {
        this.registrandoCliente = false;
      });


    },
    mostrarEnMapa(addressData) {
      //ponemos el marker
      this.markerMap.lat = addressData.latitude;
      this.markerMap.lng = addressData.longitude;
      //centramos el mapa
      this.centerMap.lat = addressData.latitude;
      this.centerMap.lng = addressData.longitude;
    }
  },
};
</script>

<style scoped>
@media (min-width: 600px) {
  .p-register {
    padding-right: 8rem;
    padding-left: 8rem;
  }
}

@media (min-width: 800px) {
  .p-register {
    padding-right: 10rem;
    padding-left: 10rem;
  }
}

@media (min-width: 1400px) {
  .p-register {
    padding-right: 13rem;
    padding-left: 13rem;
  }
}

@media (min-width: 1900px) {
  .p-register {
    padding-right: 16rem;
    padding-left: 16rem;
  }
}

.text-sm {
  font-size: 14px;
}


</style>
